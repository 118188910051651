import {
  GL_COLORS,
  GLDateRangeType,
  GLRelativeFilterTimeProps,
  GLRelativeListOption,
  GLRelativeListOptionID,
  useEventListStore,
  useI18n,
} from "@group-link-one/grouplink-components";
import { subDays } from "date-fns";
import { useEffect } from "react";
import { useMediaQuery } from "usehooks-ts";

export const useRelativeTimeFilter = () => {
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const { state: eventListState, actions: eventListActions } =
    useEventListStore();

  const { t } = useI18n();

  const options: GLRelativeListOptionID[] = [
    "last_5_minutes",
    "last_15_minutes",
    "last_30_minutes",
    "last_1_hour",
    "last_3_hours",
    "last_6_hours",
    "last_12_hours",
    "last_24_hours",
    "last_3_days",
    "last_7_days",
    "last_15_days",
    "last_30_days",
  ];

  const relativeFilterTimeOptions: GLRelativeFilterTimeProps = {
    rootOptions: {
      // width: !isMobile ? "fit-content" : "calc(100vw - 80px)",
      width: "fit-content",
      style: {
        paddingRight: isMobile ? 5 : 0,
        background: isMobile ? "transparent" : "inherit",
        alignItems: isMobile ? "flex-end" : "initial",
      },
    },
    inputOptions: {
      width: "fit-content",
      isToggle: eventListState.devices_id_to_request.length > 0,
    },
    boxOptions: {
      position: "bottom-right",
      // width: isMobile ? "100%" : "600px",
      width: !isMobile ? "600px" : "calc(100vw - 80px)",

      style: {
        boxShadow: `0px 4px 4px ${GL_COLORS.SHADOW_COLOR}`,
        zIndex: 1000,
      },
    },
    maxDateFrom: 60,
    currentRelativeTime: eventListState.currentRelativeTime,
    currentDateRange: eventListState.currentDateRange,
    options,
    setCurrentRelativeTime: (option?: GLRelativeListOption) => {
      eventListActions.setCurrentRelativeTime(option);
    },

    setCurrentDateRange: (range?: GLDateRangeType) => {
      eventListActions.setCurrentDateRange(range);
    },

    onRangeChange: async (range?: GLDateRangeType) => {
      if (!range?.from || !range?.to) return;
      eventListActions.setRange(range);
    },
  };

  useEffect(() => {
    eventListActions.setCurrentRelativeTime({
      id: "last_24_hours",
      range: {
        from: subDays(new Date(), 1),
        to: new Date(),
      },
      text: t("days.last24Hours"),
    });
  }, []);

  return {
    t,
    relativeFilterTimeOptions,
  };
};
