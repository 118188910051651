import { EventListCardsProps } from "./EventListCards.types";
import { TrackCard } from "./Track/TrackCard";
import { useEventListCard } from "./useEventListCard";

export function EventListCards({ events }: EventListCardsProps) {

  const { onSeeMoreClick } = useEventListCard()

  return (
    <>
      {events?.map((event, index) => {
        return <TrackCard key={event.eventCreated + index} event={event} onSeeMoreClick={onSeeMoreClick} />;
      })}
    </>
  );
}
