import { useI18n } from "@group-link-one/grouplink-components";
import { format, formatDistance, isToday } from "date-fns";
import { DateTime } from "luxon";
import { useState } from "react";
import { useMediaQuery } from "usehooks-ts";

import { GetEventListResponse } from "../../../Services/eventListService/useEventListService.types";
import { useTrackEasyStore } from "../../TrackEasyMap/store/track-easy-map-store";

export const useEventListCard = () => {
  const isMobile = useMediaQuery("(max-width: 1024px)");
  const { t } = useI18n();

  const { actions } = useTrackEasyStore();
  const [address, setAddress] = useState("");

  function formatTime(timestamp: number): string {
    const dt = DateTime.fromMillis(timestamp, { zone: "utc" });

    if (dt.hour > 0) return dt.toFormat("HH'h 'mm'm'");

    if (dt.minute > 0) return dt.toFormat("mm'm 's's'");

    if (dt.second > 0) return dt.toFormat("s's");

    if (dt.millisecond > 0) return dt.toFormat("SSS") + "ms";

    return "--";
  }

  function formatLiters(milliliters: number): string {
    if (milliliters < 1000) {
      return `${milliliters.toFixed(2)} ml`;
    }

    const liters = Math.floor(milliliters / 1000);
    const remainingMilliliters = milliliters % 1000;

    if (remainingMilliliters === 0) {
      return `${liters}L`;
    } else {
      return `${liters}.${remainingMilliliters.toFixed(0)} L`;
    }
  }

  function formatEnergyValue(value: string) {
    const numericValue = parseInt(value, 10);

    const kWhValue = numericValue / 1000;

    const formattedValue = kWhValue.toFixed(2);

    return formattedValue + " kWh";
  }

  function formatDateDistanceText(date: string) {
    const isTodayDate = isToday(new Date(date));

    if (isTodayDate) {
      return format(new Date(date), "hh:mm a");
    }

    return formatDistance(new Date(date), new Date(), {
      addSuffix: true,
    });
  }

  function onSeeMoreClick(event: GetEventListResponse) {
    actions.setDeviceSelected(event);
    actions.setIsEventListModalOpen(true);
  }

  const reverseGeocode = (locationParam: { lat: number, lng: number }) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ location: locationParam }, (results, status) => {
      if (status === "OK" && results && results[0]) {
        setAddress(results[0].formatted_address);
        console.log("Endereço:", results[0].formatted_address);
      } else {
        console.error("Erro na geocodificação reversa:", status);
      }
    });
  };

  return {
    t,
    address,
    formatTime,
    formatLiters,
    formatEnergyValue,
    formatDateDistanceText,
    isMobile,
    reverseGeocode,
    onSeeMoreClick
  };
};
