import { GL_COLORS } from "@group-link-one/grouplink-components";
import { GoogleMap } from "@react-google-maps/api";

import { DeviceModal } from "./DeviceModal/DeviceModal";
import { MarkerMap } from "./Marker/Marker";
import { useTrackEasyMap } from "./useTrackEasyMap";

const containerStyle: React.CSSProperties = {
  width: "100%",
  height: "100%",
};

export function TrackEasyMap() {
  const {
    center,
    mapStyle,
    isLoaded,
    onLoad,
    onUnmount
  } = useTrackEasyMap();

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={{
          ...containerStyle,
          borderRadius: "10px",
          border: `1px solid ${GL_COLORS.BORDER_COLOR}`
        }}
        options={{
          styles: mapStyle,
          disableDefaultUI: true,
          draggableCursor: "default",
          draggingCursor: "arrow",
          zoomControl: false,
          minZoom: 15,
          maxZoom: 15,
          gestureHandling: "none",
          scrollwheel: false,

          // maxZoom: 20,
        }}
        center={center}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <MarkerMap />
        <DeviceModal />
      </GoogleMap>
    </>

  ) : <></>;
}
