import { GLEventList } from "@group-link-one/grouplink-components";

import { EventListTableContent } from "./Content/TableContent";
import { useRelativeTimeFilter } from "./Content/useRelativeTimeFilter";
import { EventListModalMap } from "./ModalMap/EventListModalMap";

export const EventList = () => {
  const { relativeFilterTimeOptions } = useRelativeTimeFilter();
  // const { state } = useEventListStore()

  return (
    <>
      <GLEventList
        hasFilterByVictimId={false}
        tableContent={<EventListTableContent />}
        relativeFilterTimeOptions={relativeFilterTimeOptions}
        hasFilterByRelativeTime
      />

      <EventListModalMap />
    </>

  );
};
