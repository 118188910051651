import { useI18n } from "@group-link-one/grouplink-components";

export const useTrackColumns = () => {
  const { t } = useI18n();

  return [
    {
      columnName: t("eventList.columns.created_at"),
      hasActions: false,
    },
    {
      columnName: t("eventList.columns.sent"),
      hasActions: false,
    },
    {
      columnName: t("eventList.columns.device_id"),
    },
    {
      columnName: t("eventList.columns.locLatitude"),
      hasActions: false,
    },
    {
      columnName: t("eventList.columns.locLongitude"),
      hasActions: false,
    },
    {
      columnName: t("eventList.columns.locTime"),
      hasActions: false,
    },
    {
      columnName: t("Actions"),
      hasActions: false,
    },
  ];
};
