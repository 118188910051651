import {
  useEventListStore,
  useI18n,
  useToast,
} from "@group-link-one/grouplink-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { useEffect, useMemo, useState } from "react";

import { useEventListService } from "../../Services/eventListService/useEventListService";
import { GetEventListResponse } from "../../Services/eventListService/useEventListService.types";
import { useAllColumns } from "./Content/Columns/AllColumns";

export const useEventList = () => {
  const { t } = useI18n();
  const { getEventList, getDevices } = useEventListService();
  const { actions, state } = useEventListStore();
  const { addToast } = useToast();
  const [hasEvents, setHasEvents] = useState(false)

  const queryClient = useQueryClient();

  const columns = useAllColumns();

  const fromDate = useMemo(() => {
    return format(
      new Date(state.range.from || new Date()),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
    )
  }, [state.range.from])

  const toDate = useMemo(() => {
    return format(
      new Date(state.range.to || new Date()),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
    )
  }, [state.range.to])

  const searchDeviceID = useMemo(() => {

    if (
      state.searchDeviceId.length >= 0 &&
      state.searchDeviceId.length < 10
    ) return undefined;

    return isNaN(Number(state.searchDeviceId))
      ? undefined
      : [Number(state.searchDeviceId)];

  }, [state.searchDeviceId]);

  const {
    data: events,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: [
      "get-event-list",
      fromDate,
      toDate,
      state.devices_id_to_request,
      state.hasCheckFilterByVictimId,
    ],
    queryFn: async () => {
      if (!state.range.from || !state.range.to) return [];

      if (state.devices_id_to_request.length > 0 ) {
        const devicesIDFormatted = state.devices_id_to_request?.map((deviceid) =>
          Number(deviceid)
        );


      try {
        const response = await getEventList({
          from: format(
            new Date(state.range.from || new Date()),
            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
          ),
          until: format(
            new Date(state.range.to || new Date()),
            "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
          ),
          // next_page_token: state.next_page_token,
          next_page_token: undefined,
          device_id: devicesIDFormatted,
          application: "GLTracking",
          ipp: 40,
          user_id: state.hasCheckFilterByVictimId ? 1987392100 : undefined,
        });

        actions.setNextPageToken(response.next_page_token || "");

        if (response.rows.length === 0) {
          actions.setEmptystate(
            t("eventList.list.emptyState.title2"),
            t("eventList.list.emptyState.description2")
          );

          addToast({
            title: `${t("attention")}!`,
            message: t("eventList.list.emptyState.description2"),
            type: "info",
          });

          return;
        }

        actions.setError(false);

        actions.setHasCheckFilterByVictimId(true);
        return response.rows.filter(
          (event) =>
            event.locLatitude !== "" && checkLocTime(event.eventCreated, Number(event.locTime || 0))
        );
      } catch (error: any) {
        if (
          error.response.data.message[0] ===
          "from must be a valid ISO 8601 date string" ||
          error.response.data.message[1] ===
          "until must be a valid ISO 8601 date string"
        ) {
          addToast({
            title: t("eventList.errors.title"),
            message: t("eventList.errors.message"),
            type: "error",
          });
          actions.setError(true);
        }
        actions.setError(true);
      }
    }
    },
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const previousData = queryClient.getQueryData(["get-event-list"]);

  async function getMoreEvents() {
    const devicesIDFormatted = state.devices_id_to_request?.map((deviceid) =>
      Number(deviceid)
    );

    if (!state.next_page_token) return [];

    actions.setIsFetchingMoreEvents(true);

    const response = await getEventList({
      from: fromDate,
      until: toDate,
      next_page_token: state.next_page_token,
      device_id: devicesIDFormatted,
      application: "GLTracking",
      ipp: 40,
      user_id: state.hasCheckFilterByVictimId ? 1987392100 : undefined,
    });

    actions.setIsFetchingMoreEvents(false);

    actions.setNextPageToken(response.next_page_token || "");

    if (response.rows.length === 0) {
      actions.setEmptystate(
        t("eventList.list.emptyState.title2"),
        t("eventList.list.emptyState.description2")
      );

      addToast({
        title: `${t("attention")}!`,
        message: t("eventList.list.emptyState.description2"),
        type: "info",
      });

      return;
    }

    actions.setError(false);

    return response.rows;
  }

  function setDevicesIDAvailable(eventList: GetEventListResponse[]) {
    const devicesID = eventList.map((event) => event.deviceId);

    if (!devicesID || devicesID.length === 0) {
      actions.setDevicesId([]);
      return;
    }

    const uniqueDevicesID = Array.from(new Set(devicesID));
    actions.setDevicesId(uniqueDevicesID);
  }

  const isAtBottom = ({ currentTarget }: React.UIEvent<HTMLElement>) => {
    const finalScroll = currentTarget.scrollHeight - currentTarget.clientHeight;

    return (
      finalScroll - currentTarget.scrollTop < 100 &&
      finalScroll - currentTarget.scrollTop > -100
    );
  };

  const handleScroll = async (event: React.UIEvent<HTMLElement>) => {
    if (!isAtBottom(event) || state.isFetchingMoreEvents) return;

    const moreEvents = await getMoreEvents();

    if (!moreEvents || moreEvents.length === 0) return;

    const currentData: GetEventListResponse[] | undefined =
      queryClient.getQueryData([
        "get-event-list",
        fromDate,
        toDate,
        state.devices_id_to_request,
        state.hasCheckFilterByVictimId ? 1987392100 : "nohasCheckFilterByVictimId",
      ]);

    if (!currentData) return;

    queryClient.setQueryData(
      ["get-event-list", fromDate, toDate, state.devices_id_to_request],
      currentData.concat(moreEvents)
    );

    setDevicesIDAvailable(currentData.concat(moreEvents));
  };

  const { data: listDeviceIDs, isLoading: listDeviceIDsIsLoading } = useQuery({
    queryKey: ["get-devices-id-event-list", searchDeviceID],
    refetchOnWindowFocus: false,
    queryFn: async () => {

      const response = await getDevices();

      return response.data.rows;
    },
  })

  useEffect(() => {
    if (!events || events.length === 0) {
      actions.setHasCheckFilterByVictimId(false);
      return
    }
    setDevicesIDAvailable(events);
  }, [events]);

  useEffect(() => {
    actions.resetAll();
  }, [])

  useEffect(() => {
    if (listDeviceIDs === undefined) return
    const devicesID: string[] = listDeviceIDs.map((device: any) => device.device_id)
    const uniquesDevicesID = Array.from(new Set(devicesID))

    actions.setDevicesId(uniquesDevicesID)
  }, [listDeviceIDs])

  useEffect(() => {
    actions.setDeviceIsLoading(listDeviceIDsIsLoading)
  }, [listDeviceIDsIsLoading])

  const filteredEvents = events?.filter(
    (event) =>
      event.locLatitude !== "" && checkLocTime(event.eventCreated, Number(event.locTime || 0))
  );

  function checkLocTime(time: number, locTime: number): boolean {
    if (time - locTime < 1726864737622) {
      return true;
    }

    return false;
  }

  useEffect(() => {
    if (filteredEvents && filteredEvents.length > 0) {
      setHasEvents(true);
    }
  }, [filteredEvents]);

  return {
    events,
    handleScroll,
    // devices_id,
    isLoading,
    previousData,
    // deviceLoading,
    actions,
    state,
    refetch,
    columns,
    filteredEvents,
    checkLocTime,
    hasEvents
  };
};
