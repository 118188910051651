import React from "react";

interface StreetViewImageProps {
  lat: number;
  lng: number;
  apiKey: string;
  width?: number;
  height?: number;
  heading?: number;
  pitch?: number;
  style?: React.CSSProperties;
}

const StreetViewImage: React.FC<StreetViewImageProps> = ({
  lat,
  lng,
  apiKey,
  width = 600,
  height = 300,
  heading = 0,
  pitch = 0,
  style
}) => {
  const imageUrl = `https://maps.googleapis.com/maps/api/streetview?size=${width}x${height}&location=${lat},${lng}&fov=80&heading=${heading}&pitch=${pitch}&key=${apiKey}`;

  return (
    <div style={style}>
      <img src={imageUrl} alt="Street View" style={{ width, height, objectFit: "cover" }} />
    </div>
  );
};

export default StreetViewImage;
