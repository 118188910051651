import { useAuth } from "@group-link-one/gl-auth";
import {
  GLPageNotFound,
  useGLModalLogout,
} from "@group-link-one/grouplink-components";
import { AnimatePresence } from "framer-motion";
import { useEffect, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useFlags } from "../Context/FlagsProvider";
import Logo404 from "../images/art_404.svg";
import RightLogo404 from "../images/bg_404.svg";
import GLLogoDark from "../images/Common/logo-glutilities-dark.svg";
import GLLogo from "../images/Common/logo-glutilities-light.svg";
import { EventListLayout } from "../layouts/EventListLayout/EventListLayout";
import { MainLayout } from "../layouts/MainLayout/MainLayout";
import { OnboardLayout } from "../layouts/OnboardLayout/OnboardLayout";
import { SettingsLayout } from "../layouts/SettingsLayout/SettingsLayout";
import { EventList } from "../Pages/EventList/eventList";
import { NewPassword } from "../Pages/Onboard/CreateNewPassword/newPassword";
import { SignUp } from "../Pages/Onboard/SignUp/signUp";
import { LoginSecurity } from "../Pages/Settings/LoginSecurity/loginSecurity";
import { MyAccount } from "../Pages/Settings/MyAccount/myAccount";
import { Organizations } from "../Pages/Settings/Organizations/organizations";
import { Overview } from "../Pages/Settings/Overview/overview";
import { Users } from "../Pages/Settings/Users/organizationUsers";
import { VerifyRoute } from "./VerifyRoute";

export const AuthenticatedRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { manipulateReduxValues, accessToken, accessTokenIsValid } = useAuth();
  const { user } = useFlags();

  const { handleOpenModalLogout, modalLogoutIsOpenOpen } = useGLModalLogout();

  const indexRoute = useMemo(() => {
    return "/settings/overview";
  }, [user]);

  useEffect(() => {
    manipulateReduxValues("SET_ACTION_ON_IDLE", handleOpenModalLogout);
  }, []);

  useEffect(() => {
    if (accessToken && !accessTokenIsValid && !modalLogoutIsOpenOpen) {
      handleOpenModalLogout();
    }
  }, []);

  return (
    <AnimatePresence mode="wait" initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={<VerifyRoute needAuth={true} element={<MainLayout />} />}
        >
          <Route
            path="settings"
            element={
              <VerifyRoute needAuth={true} element={<SettingsLayout />} />
            }
          >
            <Route
              index
              element={
                <VerifyRoute
                  needAuth={true}
                  element={<Navigate to="overview" />}
                />
              }
            />

            <Route
              path="overview"
              element={<VerifyRoute needAuth={true} element={<Overview />} />}
            />

            <Route
              path="my-account"
              element={<VerifyRoute needAuth={true} element={<MyAccount />} />}
            />

            <Route
              path="login-security"
              element={
                <VerifyRoute needAuth={true} element={<LoginSecurity />} />
              }
            />

            <Route
              path="users"
              element={<VerifyRoute needAuth={true} element={<Users />} />}
            />

            <Route path="organizations" element={<Organizations />} />
          </Route>

          <Route
            path="network"
            element={
              <VerifyRoute needAuth={true} element={<EventListLayout />} />
            }
          >
            <Route
              path="event-list"
              element={<VerifyRoute needAuth={true} element={<EventList />} />}
            />
          </Route>

          <Route
            index
            path="/"
            element={
              <VerifyRoute
                needAuth={true}
                element={<Navigate to={indexRoute} />}
              />
            }
          />

          {accessToken && (
            <>
              <Route element={<OnboardLayout />}>
                <Route path="/join-organization" element={<SignUp />} />
                <Route path="/new-password" element={<NewPassword />} />
              </Route>

              <Route
                path="*"
                element={
                  <GLPageNotFound
                    logoOptions={{
                      src: {
                        logoLight: GLLogo,
                        logoDark: GLLogoDark,
                      },
                      backgroundImage: RightLogo404,
                      alt: "GroupLink logo",
                      logoRight: Logo404,
                    }}
                    onClickButton={() => navigate("/")}
                    title="Oops!"
                    subtitle="Page not found"
                    description="It looks like you were detached from our IoT world..."
                  />
                }
              />
            </>
          )}
        </Route>
      </Routes>
    </AnimatePresence>
  );
};
