import { GL_COLORS, GLBox, GLDetailedModal } from "@group-link-one/grouplink-components";

import { useTrackEasyStore } from "../../TrackEasyMap/store/track-easy-map-store";
import { TrackEasyMap } from "../../TrackEasyMap/TrackEasyMap";

export function EventListModalMap() {

  const {
    state: trackEasyState,
  } = useTrackEasyStore();

  return (
    <GLDetailedModal
      open={trackEasyState.eventListModalIsOpen}
      title="Localização da tornozeleira"
      width="100%"
      heigth="100%"
      closeIconColor={GL_COLORS.DANGER}
      asFullScreen={true}
      content={
        <GLBox width="100%" height="100%">
          <TrackEasyMap />
        </GLBox>
      }
    />
  )
}
