
import { useEventListStore } from "@group-link-one/grouplink-components";
import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";

import { GetEventListResponse } from "../../../Services/eventListService/useEventListService.types";
import { useTrackEasyStore } from "../store/track-easy-map-store";

export const useDeviceModal = () => {

  const [refBoxParentListHeight, setRefBoxParentListHeight] = useState<number>(0)

  const {
    state: trackEasyState,
    actions: trackEasyActions,
  } = useTrackEasyStore()

  const { state } = useEventListStore();
  const queryClient = useQueryClient();

  const currentDeviceChannel = trackEasyState.deviceSelected

  const refBoxParentList = useRef<HTMLDivElement>(null)

  const fromDate = useMemo(() => {
    return format(
      new Date(state.range.from || new Date()),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
    )
  }, [state.range.from])

  const toDate = useMemo(() => {
    return format(
      new Date(state.range.to || new Date()),
      "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
    )
  }, [state.range.to])

  const currentEventsCached: GetEventListResponse[] | undefined = queryClient.getQueryData([
    "get-event-list",
    fromDate,
    toDate,
    [trackEasyState.deviceSelected?.deviceId],
    state.hasCheckFilterByVictimId,
  ])

  function onClose() {
    trackEasyActions.setDeviceSelected(undefined)
    trackEasyActions.setIsEventListModalOpen(false)
  }

  useEffect(() => {
    setTimeout(() => {
      if (!refBoxParentList.current) return

      const { height } = refBoxParentList.current.getBoundingClientRect()

      setRefBoxParentListHeight(height - 50)
    }, 300)
  }, [refBoxParentList.current, trackEasyState.eventListModalIsOpen])

  return {
    trackEasyState,
    trackEasyActions,
    refBoxParentListHeight,
    refBoxParentList,
    currentDeviceChannel,
    currentEventsCached,
    onClose
  };
};
